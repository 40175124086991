import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingIcon from '~/icons/components/LoadingIcon';
import { isYitty } from '~/shared/utils/brandNameHelper';
import {
  YittyLogo,
  FableticsMarkLogo,
} from '~/techstyle-shared/react-components';

const LoadingSpinnerWithLogoContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 4px;
  line-height: 0.7;
`;

const BrandLogoWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

const LoadingSpinnerWrapper = styled.div``;

const BrandIcon = ({ size }) => {
  if (isYitty()) {
    return <YittyLogo width={size} />;
  }

  return <FableticsMarkLogo width={size} />;
};

const AnimatedLoadingIcon = styled(LoadingIcon)`
  animation: ${({ theme }) => theme.animations.keyframes.spin} 1.5s linear
    infinite;
`;

BrandIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default function LoadingSpinnerFab({ size = 58, center, className }) {
  return (
    <LoadingSpinnerWithLogoContainer>
      <LoadingSpinnerWrapper $center={center}>
        <AnimatedLoadingIcon
          size={1.1 * size}
          strokeWidth={4}
          className={className}
        />
      </LoadingSpinnerWrapper>
      <BrandLogoWrapper>
        <BrandIcon size={0.4 * size} />
      </BrandLogoWrapper>
    </LoadingSpinnerWithLogoContainer>
  );
}

LoadingSpinnerFab.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  AuthenticationErrorTypes,
  LoginErrorMessage,
} from '~/login/utils/LoginErrorMessages';
import InputFields from '~/shared/components/InputFields';
import {
  Button,
  PasswordField,
  mobile,
} from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import { PinVerificationFormTitle } from '../PinVerificationForm/PinVerificationForm';
import PinVerificationMethods from '../PinVerificationMethods/PinVerificationMethods';

const StyledPasswordField = styled(PasswordField)`
  fieldset {
    border-bottom: 1px solid transparent;
  }
`;

const actionButtonMobileStyles = css`
  height: 52px;
  width: 100%;
`;

const LoginButton = styled(Button).attrs({
  variant: 'primary',
})`
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  min-width: 270px;
  ${mobile`
    ${actionButtonMobileStyles}
    margin-bottom: 12px;
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ForgotPasswordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
`;

const ForgotPasswordControlButton = styled(Button).attrs({
  variant: 'unstyled',
})`
  padding: 0;
  align-self: flex-end;
  ${({ theme }) => theme.loginForm.forgotPassword};
  color: ${({ theme }) => theme.colors.loginAuthForgotPasswordControlButton};
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 14px;
  left: 12px;
  ${mobile`
    top: 34px;
  `}
`;

const AuthLayoutContainer = styled.div`
  min-width: 315px;
  max-width: 500px;
`;

function AuthLayout({
  setForgotPassword,
  email,
  onVerificationMethodSelect,
  handlePasswordSubmit,
  loginError,
  isSubmitting,
  password,
  setPassword,
}) {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (password.length >= 6) {
      setIsValid(true);
    }
  }, [password]);

  if (!email) {
    return null;
  }

  return (
    <AuthLayoutContainer>
      <PinVerificationFormTitle>
        <FormattedMessage
          id="site_login.identity_verification_modal_heading"
          defaultMessage="Verify Your Identity"
        />
      </PinVerificationFormTitle>

      <PinVerificationMethods
        email={email}
        onVerificationMethodSelect={onVerificationMethodSelect}
        showVerifyIdentityTitle
      />

      <>
        {loginError !== AuthenticationErrorTypes.BLOCKED_LOGIN && (
          <InputFields>
            <StyledPasswordField
              name="password"
              variant="standard"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              label={
                <FormattedMessage
                  id="global_cta.password"
                  defaultMessage="Password *"
                />
              }
              data-autotag="login_page_password"
              error={loginError === AuthenticationErrorTypes.LOGIN_FAILED}
            />
          </InputFields>
        )}
        {loginError && <LoginErrorMessage error={loginError} />}

        <ForgotPasswordContainer>
          <ForgotPasswordControlButton
            data-autotag="forgot_password_link"
            onClick={setForgotPassword}
          >
            <FormattedMessage
              id="site_login.forgot_password1"
              defaultMessage="Forgot your password?"
            />
          </ForgotPasswordControlButton>
        </ForgotPasswordContainer>

        {loginError !== AuthenticationErrorTypes.BLOCKED_LOGIN && (
          <Actions>
            <LoginButton
              type="submit"
              disabled={isSubmitting || !isValid}
              isLoading={isSubmitting}
              data-autotag="login_page_login_btn"
              onClick={handlePasswordSubmit}
            >
              <FormattedMessage
                id="site_login.Sign_In"
                defaultMessage="Sign In"
              />
            </LoginButton>
          </Actions>
        )}
      </>
    </AuthLayoutContainer>
  );
}

AuthLayout.propTypes = {
  email: PropTypes.string,
  handlePasswordSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  loginError: PropTypes.object,
  onVerificationMethodSelect: PropTypes.func,
  password: PropTypes.string,
  setForgotPassword: PropTypes.func,
  setPassword: PropTypes.func,
};

AuthLayout.BackButtonContainer = BackButtonContainer;

export default AuthLayout;

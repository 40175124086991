import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useAccountActions } from '~/techstyle-shared/react-accounts';
import { Button } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

const PinVerificationFormErrors = {
  INVALID_PIN: 4000,
  INCORRECT_PIN: 4001,
  SERVER_ERROR: 502,
  UNKNOWN_ERROR: 501,
  INCORRECT_PIN2: 400,
};

const PIN_LENGTH = 6;

function PinVerificationErrorMessage({ error }) {
  // MAKE SURE INVALID PIN ERRORS SHOW:
  switch (error) {
    case PinVerificationFormErrors.INVALID_PIN:
    case PinVerificationFormErrors.INCORRECT_PIN:
    case PinVerificationFormErrors.INCORRECT_PIN2:
      return (
        <PinErrorContainer>
          <FormattedMessage
            id="site_login.invalid_code"
            defaultMessage="Invalid code! Try again."
          />
        </PinErrorContainer>
      );
    case PinVerificationFormErrors.SERVER_ERROR:
    case PinVerificationFormErrors.UNKNOWN_ERROR:
      return (
        <PinErrorContainer>
          <FormattedMessage
            id="app_global.404_error_message_yitty" // this is not final
            defaultMessage="Something went wrong. We are working to fix it ASAP. Please try again!"
          />
        </PinErrorContainer>
      );
    default:
      return null;
  }
}

PinVerificationErrorMessage.propTypes = {
  error: PropTypes.string,
};

export const PinVerificationFormTitle = styled.h1`
  font-size: 32px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 16px;
`;

const PinVerificationTypeText = styled.div`
  font-size: 14px;
  font-weight: 200;
  text-align: center;
  margin: 12px 0;
`;

const SegmentedDigitInputContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px; /* Spacing between digit boxes */
  margin: 10px;
`;

const DigitInput = styled.input`
  width: 35px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  border: 1px solid
    ${({ theme }) => theme.colors.pinVerificationFormDigitInputBorder};
  border-radius: 4px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.palette.black};
    outline: none;
  }
`;

const ResendCodeTextContainer = styled.div`
  margin-bottom: 10px;
  width: 90%;
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.pinVerificationFormResendCodeText};
`;

const SubmitButton = styled(Button)`
  margin: 15px auto;
  margin-bottom: ${({ theme }) => theme.sizes.sm}px;
  width: 90%;
`;

const ResendCodeLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  margin-left: 6px;
  font-weight: 500;
`;

const PinErrorContainer = styled.div`
  color: ${({ theme }) => theme.colors.errorMessage};
  margin: 10px auto;
  width: 90%;
  text-align: center;
`;

const PinVerificationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SegmentedDigitInput = ({ digits, setDigits }) => {
  const inputRefs = useRef([]);

  const handleChange = (index) => (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const newDigits = [...digits];

    if (value === '') {
      newDigits[index] = '';
    } else if (value.length === 1) {
      newDigits[index] = value;
      if (index < PIN_LENGTH - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      // Handle paste event
      value
        .split('')
        .slice(0, PIN_LENGTH)
        .forEach((digit, i) => {
          if (index + i < PIN_LENGTH) {
            newDigits[index + i] = digit;
          }
        });

      const nextIndex = index + value.length;
      if (nextIndex < PIN_LENGTH) {
        inputRefs.current[nextIndex].focus();
      } else {
        inputRefs.current[PIN_LENGTH - 1].focus();
      }
    }

    setDigits(newDigits);
  };

  const handleKeyDown = (index) => (e) => {
    if (e.key === 'Backspace' && digits[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <SegmentedDigitInputContainer>
      {digits.map((digit, index) => (
        <DigitInput
          key={index}
          type="text"
          value={digit}
          onChange={handleChange(index)}
          onKeyDown={handleKeyDown(index)}
          ref={(el) => (inputRefs.current[index] = el)}
          autoComplete={index === 0 ? 'one-time-code' : 'off'}
        />
      ))}
    </SegmentedDigitInputContainer>
  );
};

SegmentedDigitInput.propTypes = {
  digits: PropTypes.array.isRequired,
  setDigits: PropTypes.func.isRequired,
};

const PinVerificationForm = ({ verificationMethod, email, onSuccess }) => {
  const actions = useAccountActions();
  const [digits, setDigits] = useState(Array(PIN_LENGTH).fill(''));
  const [error, setError] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pathname } = useRouter();

  // A pin is valid if it is PIN_LENGTH digits long and all characters are numbers
  const isPinValid = (pin) => {
    const pinString = pin.join('');
    if (!pinString || pinString.length !== PIN_LENGTH) {
      return false;
    }
    if (!parseInt(pinString)) {
      return false;
    }
    return true;
  };

  const handleResendVerificationCode = useCallback(() => {
    setIsResendEnabled(false);
    const destination = pathname.replace(/^\//, '');
    actions.requestMultifactorPin({
      type: verificationMethod,
      destination: destination,
      email: email,
    });
    setTimeout(() => {
      setIsResendEnabled(true);
    }, 300000);
  }, [actions, verificationMethod, pathname, email]);

  const handlePinSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const pin = parseInt(digits.join(''), 10);
    setError(null);
    setIsButtonEnabled(false);
    try {
      const response = await actions.submitMultifactorPin({ pin, email });
      if (response && onSuccess) {
        onSuccess();
      }
    } catch (err) {
      const error = err;
      let statusCode = err.statusCode;
      let errorCode;

      if (err.originalError && err.originalError.response) {
        const responseBody = await err.originalError.response.json();
        if (responseBody) {
          if (responseBody.errorCode) {
            errorCode = responseBody.errorCode;
          } else if (responseBody.statusCode) {
            statusCode = responseBody.statusCode;
          }
        }
      }
      if (error) {
        if (errorCode) {
          setError(errorCode);
        } else if (statusCode) {
          setError(statusCode);
        } else {
          setError(PinVerificationFormErrors.UNKNOWN_ERROR);
        }
      }
      setIsSubmitting(false);
      setIsButtonEnabled(true);
    }
  }, [actions, digits, email, onSuccess]);

  useEffect(() => {
    if (!isPinValid(digits)) {
      setIsButtonEnabled(false);
      return;
    }
    if (!isSubmitting) {
      setIsButtonEnabled(true);
      if (!error) {
        // If we're not submitting and there's no current error, then autosubmit the pin
        handlePinSubmit();
      }
    }
  }, [digits, handlePinSubmit, isSubmitting, error]);

  return (
    <PinVerificationFormContainer>
      <PinVerificationFormTitle>
        <FormattedMessage
          id="site_login.identity_verification_modal_heading"
          defaultMessage="Verify Your Identity"
        />
      </PinVerificationFormTitle>
      <PinVerificationTypeText>
        {verificationMethod === 'sms' ? (
          <FormattedMessage
            id="site_login.verify_phone_subtext"
            defaultMessage="Please check your text messages for a verification code."
          />
        ) : (
          <FormattedMessage
            id="site_login.verify_email_subtext"
            defaultMessage="Please check your email account for a verification code."
          />
        )}
      </PinVerificationTypeText>

      <SegmentedDigitInput
        submitPin={handlePinSubmit}
        digits={digits}
        setDigits={setDigits}
      />

      <PinVerificationErrorMessage error={error} />

      <SubmitButton
        variant="primary"
        type="submit"
        disabled={!isButtonEnabled}
        data-autotag="verify_your_identity_login_btn"
        onClick={() => {
          if (isPinValid(digits)) {
            handlePinSubmit();
          }
        }}
      >
        <FormattedMessage
          id="site_login.submit_pin_code"
          defaultMessage="Submit"
        />
      </SubmitButton>
      <ResendCodeTextContainer>
        {isResendEnabled ? (
          <>
            <FormattedMessage
              id="site_login.identity_verification_modal_resend_copy"
              defaultMessage="Didn't receive the code?"
            />
            <ResendCodeLink onClick={handleResendVerificationCode}>
              <FormattedMessage
                id="site_login.identity_verification_modal_resend_link"
                defaultMessage="Resend Now"
              />
            </ResendCodeLink>
          </>
        ) : (
          <FormattedMessage
            id="site_login.identity_verification_modal_resend_link_confirm"
            defaultMessage="A new code has been sent."
          />
        )}
      </ResendCodeTextContainer>
    </PinVerificationFormContainer>
  );
};

PinVerificationForm.propTypes = {
  email: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  verificationMethod: PropTypes.string.isRequired,
};

export default PinVerificationForm;

import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  setLocalStorageItemWithExpiry,
  getLocalStorageItemWithExpiry,
  localStorageItemStatus,
} from '~/shared/utils/localStorageHelper';
import { useMembership } from '~/techstyle-shared/react-accounts';
import { useCookies, useSession } from '~/techstyle-shared/redux-core';

const STORAGE_KEY = 'shoppable_video_history';
const LAST_USER_COOKIE_ID = 'shoppable_video.last_user';
const CURRENT_VIDEO_COOKIE_ID = 'shoppable_video.current_video';

// Set expiry to 12 hours
const EXPIRY = 12 * 3600 * 1000;

export default function useVideoHistory() {
  const { sessionId, isLoggedIn } = useSession();
  const { customerId } = useMembership();
  const userKey = useMemo(
    () => (isLoggedIn ? customerId : sessionId),
    [isLoggedIn, customerId, sessionId]
  );
  const [cookies, setCookie] = useCookies();
  const previousUserCookie = cookies[LAST_USER_COOKIE_ID];
  const currentVideoId = cookies[CURRENT_VIDEO_COOKIE_ID];

  const persistedHistory = useMemo(
    () => getLocalStorageItemWithExpiry(STORAGE_KEY) || [],
    []
  );
  const [history, setHistory] = useState(persistedHistory);

  const setCurrentVideo = useCallback(
    (id) => {
      setCookie(CURRENT_VIDEO_COOKIE_ID, id, {
        expires: new Date(Date.now() + EXPIRY),
      });
    },
    [setCookie]
  );

  const clear = useCallback(() => {
    setCurrentVideo(null);
    setHistory([]);
    setLocalStorageItemWithExpiry(STORAGE_KEY, [], EXPIRY);
  }, [setCurrentVideo]);

  useEffect(() => {
    setCookie(LAST_USER_COOKIE_ID, userKey, {
      expires: new Date(Date.now() + EXPIRY),
    });
  }, [userKey, setCookie, clear]);

  const idsMap = useMemo(() => {
    return history.reduce((acc, video) => {
      acc[video.id] = true;
      return acc;
    }, {});
  }, [history]);

  useEffect(() => {
    if (persistedHistory === localStorageItemStatus.DOES_NOT_EXIST) {
      setLocalStorageItemWithExpiry(STORAGE_KEY, [], EXPIRY);
      setHistory([]);
    }
  }, [persistedHistory]);

  const addVideo = (video) => {
    // Add the video at the end of the array. Make sure the array is unique and not more than 100 length
    const newHistory = [...history, video].slice(-100);
    idsMap[video.id] = true;
    setLocalStorageItemWithExpiry(STORAGE_KEY, newHistory, EXPIRY);
    setHistory(newHistory);
  };

  const has = (id) => idsMap[id];

  return {
    history,
    addVideo,
    clear,
    has,
    setCurrentVideo,
    currentVideoId,
    storedUserId: previousUserCookie,
  };
}

import React from 'react';

import styled from 'styled-components';

import LoadingSpinnerFab from '~/shared/components/LoadingSpinnerFab';
import { mobile } from '~/techstyle-shared/react-components';
import { FormattedHTMLMessage } from '~/techstyle-shared/react-intl';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

const WelcomeBackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 400px;
  ${mobile`
    max-width: 300px;
  `}
`;

const WelcomeBackTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
`;

const WelcomeBackText = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 16px;
  text-align: center;
`;

export default function WelcomeBackWrapper() {
  return (
    <WelcomeBackContainer>
      <WelcomeBackTitle>
        <FormattedHTMLMessage
          id="quiz.registration_screen_existing_email_welcome_back_title"
          defaultMessage="Welcome Back!"
        />
      </WelcomeBackTitle>
      <WelcomeBackText>
        <FormattedHTMLMessage
          id="quiz.registration_screen_existing_email_welcome_back"
          defaultMessage="An account with this email address already exists. You will now be logged in to your existing account."
        />
      </WelcomeBackText>

      <LoadingContainer>
        <LoadingSpinnerFab size={72} />
      </LoadingContainer>
    </WelcomeBackContainer>
  );
}

import React, { useState, useRef, useEffect, useCallback } from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import getAutoLoginByEmailRedirect from '~/login/utils/autoLoginByEmail';
import {
  AuthenticationErrorTypes,
  getAuthenticationErrorType,
} from '~/login/utils/LoginErrorMessages';
import Link from '~/shared/components/Link';
import Text from '~/shared/components/Text';
import featureNames from '~/shared/constants/featureFlags';
import { getBrandValue } from '~/shared/utils/brandNameHelper';
import scrollToRef from '~/shared/utils/scrollToRef';
import useGender from '~/shared/utils/useGender';
import useHasFeature from '~/shared/utils/useHasFeature';
import { getSecondarySite } from '~/shared/utils/useSecondarySite';
import useVideoHistory from '~/shoppableVideo/utils/useVideoHistory';
import {
  useAccountActions,
  useCustomer,
} from '~/techstyle-shared/react-accounts';
import {
  Button,
  SlidingPanels,
  mobile,
} from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import AuthLayout from '../AuthLayout/AuthLayout';
import ForgotPasswordForm from '../ForgotPasswordForm';
import PinVerificationForm from '../PinVerificationForm';
import UsernameInputField from '../UsernameInputField';
import WelcomeBackWrapper from '../WelcomeBackWrapper';

const loginGetStartedLinkInline = config.get(
  'public.brand.loginGetStartedLinkInline'
);

const StyledSlidingPanels = styled(SlidingPanels)`
  height: 100%;
  width: 100%;
`;

const LoginSlidingPanelsSection = styled.section`
  display: flex;
  align-items: center;
  font-size: 14px;
  flex: 0 1 50%;
  order: 2;
  justify-content: center;
  ${mobile`
    width: 100%;
    flex-grow: 1;
    flex-basis: 100%;
    font-size: 16px;
  `}
  > div > div {
    height: 100%;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`;

const SlidingPanel = styled.div`
  padding: 26px 50px;
  ${mobile`
    padding: 5vw 5vw 10vw;
  `}
`;

const PanelStyle = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 450px;
`;

const StyledSlidingPanel = styled(SlidingPanel)`
  ${PanelStyle}
  width: 100%;
`;

const Heading = styled(Text).attrs({
  forwardedAs: 'h2',
  variant: 'modalHeader',
})`
  ${({ theme }) => theme.loginForm.slidingPanelHeader}
`;

const Subheading = styled(Text).attrs({
  forwardedAs: 'h3',
  variant: 'modalSubheader',
})`
  margin-bottom: ${({ theme }) => theme.sizes.md}px;
  text-align: center;
  line-height: 1.25;
`;

const ResetPasswodSubheading = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.lg}px;
  text-align: center;
  line-height: 1.25;
`;

const AuthLayoutContainer = styled(AuthLayout)`
  width: 100%;
`;

const ButtonLabelWrap = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  width: auto;
  margin-bottom: 16px;

  svg {
    margin-top: 1px;
  }
`;
const StyledBackText = styled.div`
  padding-bottom: 1px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  font-size: 12px;
`;

const FaChevronLeftStyled = styled(FaChevronLeft)`
  margin-right: 0.3em;
  padding-top: 0.1em;
`;

const SlidingPanelsControlButton = styled(Button).attrs({
  variant: 'unstyled',
})`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizes.sm}px;
`;

const SignUpFooter = styled.div`
  text-align: left;
  padding-top: 16px;
`;

const QuizLink = styled.div`
  margin-top: ${({ theme }) => theme.sizes.sm}px;
  display: flex;
`;

const AutologinCTAContainer = styled(QuizLink)`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
`;

const DontHaveAccountContainer = styled.div`
  font-size: 12px;
`;

const StyledQuizLink = styled(Link)`
  font-size: 12px;
  border-bottom: 0;
  text-decoration: underline;
`;

const BackControlButton = () => {
  return (
    <ButtonLabelWrap data-autotag="login_page_back_button">
      <FaChevronLeftStyled size={12} />
      <StyledBackText>
        <FormattedMessage id="site_login.go_back" defaultMessage="Go Back" />
      </StyledBackText>
    </ButtonLabelWrap>
  );
};

const GoToQuizCTA = () => {
  const { gender } = useGender();
  const quizUrl = config.get('public.brand.quizUrl')[gender];

  return (
    <QuizLink $loginGetStartedLinkInline={loginGetStartedLinkInline}>
      <DontHaveAccountContainer variant="labelUnstyled">
        <FormattedMessage
          id="site_login.dont_have_account"
          defaultMessage="Don't have an account?"
        />
        &nbsp;
        <StyledQuizLink
          href={quizUrl}
          variant="linkEmphasized"
          data-autotag="login_page_get_started_mobile"
        >
          <FormattedMessage
            id="site_login.get_started"
            defaultMessage="Get Started"
          />
          <FaChevronRight size={10} />
        </StyledQuizLink>
      </DontHaveAccountContainer>
    </QuizLink>
  );
};

const AutologinCTA = ({ email }) => {
  const { asPath } = useRouter();
  const secondarySite = getSecondarySite(asPath);
  const { clear: clearVideoHistory } = useVideoHistory();
  const isPasswordlessLoginEnabled = useHasFeature(
    featureNames.PASSWORDLESS_LOGIN
  );

  if (!isPasswordlessLoginEnabled) {
    return null;
  }

  const onAutoLogin = () => {
    clearVideoHistory();
    const loginRedirectUrl = getAutoLoginByEmailRedirect({
      email,
      pathPrefix: secondarySite ? `/${secondarySite}` : '',
    });

    window.location.href = loginRedirectUrl;
  };

  return (
    <AutologinCTAContainer onClick={onAutoLogin}>
      <FormattedMessage
        id="site_login.auto_login_button"
        defaultMessage="Continue without signing in"
      />
    </AutologinCTAContainer>
  );
};

AutologinCTA.propTypes = {
  email: PropTypes.string,
};

export const PanelStates = {
  USERNAME_INPUT: 0,
  AUTHENTICATE_USER: 1,
  FORGOT_PASSWORD: 2,
  EMAIL_SENT: 3,
  PIN_CHALLENGE: 4,
  WELCOME_SCREEN: 5,
};

const DynamicFormattedMessage = FormattedMessage;

// Note: alError has a value of 2 when the user tries to autologin with an email that doesn't exist.
const LoginSlidingPanels = ({
  alError,
  defaultPanel,
  isPiiAuthGate = false,
  onLoginSuccess,
  hideAutologinCTA = false,
  hideGoBackCTA = false,
  hideGetStartedCTA = false,
  heading = Heading,
  ...props
}) => {
  const { query } = useRouter();
  const [error, setError] = useState(alError);
  const actions = useAccountActions();
  const [activeIndex, setActiveIndex] = useState(defaultPanel);
  const [verificationMethod, setVerificationMethod] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuspicious, setIsSuspicious] = useState(false);
  const [password, setPassword] = useState('');
  const panelRef = useRef(null);
  const collectedEmailRef = useRef(null);
  const { email } = useCustomer();
  const { clear: clearVideoHistory } = useVideoHistory();

  const isFromResetPasswordPage = query?.resetPassword;

  const scrollToPanel = () => {
    if (panelRef?.current) {
      scrollToRef(panelRef);
    }
  };

  const setActivePanel = (index = defaultPanel) => {
    setActiveIndex(index);
    scrollToPanel();
  };

  const handleSubmitEmail = () => {
    setError(null);
    setActivePanel(PanelStates.AUTHENTICATE_USER);
  };

  useEffect(() => {
    if (email) {
      collectedEmailRef.current = email;
    }
  }, [email]);

  const setEmailSent = () => {
    setActivePanel(PanelStates.EMAIL_SENT);
  };

  const setForgotPassword = () => {
    setActivePanel(PanelStates.FORGOT_PASSWORD);
  };

  const selectVerificationMethod = (method) => {
    setVerificationMethod(method);
    setActivePanel(PanelStates.PIN_CHALLENGE);
    actions.requestMultifactorPin({
      type: method,
      destination: null,
      email: collectedEmailRef.current,
    });
  };

  const handleAuthSuccess = useCallback(() => {
    clearVideoHistory();
    if (onLoginSuccess) {
      onLoginSuccess();
    }
  }, [clearVideoHistory, onLoginSuccess]);

  const handlePasswordSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setLoginError(null);

    let statusCode;
    let errorCode;

    try {
      await actions.logIn(
        {
          username: collectedEmailRef.current,
          password,
          // TODO: This will likely have to be set to `recaptchaDisabled` for Savage after Tier1 migration.
          reCaptchaResponse: getBrandValue({
            default: 'recaptchaDisabled',
            savage: null,
          }),
        },
        {
          pageWillUnmount: false,
        }
      );
      // Success: might redirect or not, depending on what `onLoginSuccess` does.
      handleAuthSuccess();
    } catch (err) {
      statusCode = err.statusCode;
      if (err.originalError && err.originalError.response) {
        const responseBody = await err.originalError.response.json();
        if (responseBody) {
          if (responseBody.errorCode) {
            errorCode = responseBody.errorCode;
          } else if (responseBody.statusCode) {
            statusCode = responseBody.statusCode;
          }
        }
      }
      const errorMessage = getAuthenticationErrorType({
        errorCode,
        statusCode,
      });
      if (AuthenticationErrorTypes.BLOCKED_LOGIN) {
        setIsSuspicious(true);
      }
      setLoginError(errorMessage);
      if (typeof grecaptcha !== 'undefined') {
        // eslint-disable-next-line no-undef
        grecaptcha.reset();
      }
      setIsSubmitting(false);
    }
  }, [actions, collectedEmailRef, password, handleAuthSuccess]);

  const headingId = isFromResetPasswordPage
    ? 'site_login.password_updated'
    : 'site_login.Welcome_Back';

  const headingDefaultMessage = isFromResetPasswordPage
    ? 'Password Updated'
    : 'Welcome back to Fabletics!';

  const PanelHeading = heading;

  return (
    <LoginSlidingPanelsSection ref={panelRef} {...props}>
      <StyledSlidingPanels activeIndex={activeIndex}>
        {/* --- Login Panel: State = 0 (USERNAME_INPUT) --- */}
        <StyledSlidingPanel id="Sliding-Panel-State-Login">
          <PanelHeading>
            <DynamicFormattedMessage
              id={headingId}
              defaultMessage={headingDefaultMessage}
            />
          </PanelHeading>
          <Subheading>
            <FormattedMessage
              id="site_login.Sign_In_Title"
              defaultMessage="Sign In"
            />
          </Subheading>
          <UsernameInputField
            onSubmitEmail={handleSubmitEmail}
            collectedEmailRef={collectedEmailRef}
            alError={error}
          />
          {!hideGetStartedCTA && <GoToQuizCTA />}
        </StyledSlidingPanel>

        {/* --- Authenticate User Panel: State = 1 (AUTHENTICATE_USER) --- */}
        <StyledSlidingPanel id="Sliding-Panel-State-Authenticate-User">
          {!isPiiAuthGate && (
            <SlidingPanelsControlButton
              onClick={() =>
                setActivePanel(activeIndex > 0 ? activeIndex - 1 : activeIndex)
              }
            >
              <BackControlButton />
            </SlidingPanelsControlButton>
          )}
          <AuthLayoutContainer
            email={collectedEmailRef.current}
            onVerificationMethodSelect={selectVerificationMethod}
            showQuizLink={!hideGetStartedCTA}
            setForgotPassword={setForgotPassword}
            handlePasswordSubmit={handlePasswordSubmit}
            loginError={loginError}
            isSubmitting={isSubmitting}
            password={password}
            setPassword={setPassword}
          />
          {!isPiiAuthGate && (
            <>
              {!hideAutologinCTA && !isSuspicious && (
                <AutologinCTA email={collectedEmailRef.current} />
              )}
              {!hideGetStartedCTA && <GoToQuizCTA />}
            </>
          )}
        </StyledSlidingPanel>

        {/* --- Forgot Password Panel: State = 2 (FORGOT_PASSWORD) --- */}
        <StyledSlidingPanel id="Sliding-Panel-State-Forgot-Password">
          <SlidingPanelsControlButton
            onClick={() => setActivePanel(PanelStates.USERNAME_INPUT)}
          >
            <BackControlButton />
          </SlidingPanelsControlButton>
          <Heading>
            <FormattedMessage
              id="site_login.forgot_password1"
              defaultMessage="Forgot your password?"
            />
          </Heading>
          <ResetPasswodSubheading>
            <FormattedMessage
              id="site_login.forgot_password2"
              defaultMessage="Enter your email address below to reset your password."
            />
          </ResetPasswodSubheading>
          <ForgotPasswordForm
            onEmailSent={setEmailSent}
            email={collectedEmailRef.current}
          />
        </StyledSlidingPanel>

        {/* --- Email Sent Panel: State = 3 (EMAIL_SENT) --- */}
        <StyledSlidingPanel id="Sliding-Panel-State-Email-Sent">
          <SlidingPanelsControlButton
            onClick={() => setActivePanel(PanelStates.USERNAME_INPUT)}
          >
            <BackControlButton />
          </SlidingPanelsControlButton>
          <Heading>
            <FormattedMessage
              id="site_login.email_sent"
              defaultMessage="Email Sent!"
            />
          </Heading>
          <ResetPasswodSubheading data-autotag="forgot_pw_success_msg">
            <FormattedMessage
              id="site_login.check_your_password_sent1"
              defaultMessage="Check your email"
            />{' '}
            <FormattedMessage
              id="site_login.your_password_sent_2"
              defaultMessage="and follow the instructions to reset your password."
            />
          </ResetPasswodSubheading>
        </StyledSlidingPanel>

        {/* --- Password Challenge Panel: State = 4 (PIN_CHALLENGE) --- */}
        <StyledSlidingPanel id="Sliding-Panel-State-pin-Challenge">
          <SlidingPanelsControlButton
            onClick={() => setActivePanel(PanelStates.AUTHENTICATE_USER)}
          >
            <BackControlButton defaultPanel={PanelStates.AUTHENTICATE_USER} />
          </SlidingPanelsControlButton>
          {verificationMethod && (
            <PinVerificationForm
              email={collectedEmailRef.current}
              goBack={() => setActivePanel(PanelStates.AUTHENTICATE_USER)}
              onSuccess={handleAuthSuccess}
              verificationMethod={verificationMethod}
            />
          )}
          {!isPiiAuthGate && !hideAutologinCTA && (
            <AutologinCTA email={collectedEmailRef.current} />
          )}
          {!hideGetStartedCTA && <GoToQuizCTA />}
        </StyledSlidingPanel>

        {/* --- Welcome Screen Panel: State = 5 (WELCOME_SCREEN) --- */}
        <StyledSlidingPanel id="Sliding-Panel-State-Welcome-Screen">
          <WelcomeBackWrapper />
        </StyledSlidingPanel>
      </StyledSlidingPanels>
    </LoginSlidingPanelsSection>
  );
};

LoginSlidingPanels.propTypes = {
  alError: PropTypes.string,
  defaultPanel: PropTypes.number,
  heading: PropTypes.node,
  hideAutologinCTA: PropTypes.bool,
  hideGetStartedCTA: PropTypes.bool,
  hideGoBackCTA: PropTypes.bool,
  isPiiAuthGate: PropTypes.bool,
  onLoginSuccess: PropTypes.func,
};

LoginSlidingPanels.defaultProps = {
  alError: null,
  defaultPanel: PanelStates.USERNAME_INPUT,
  hideAutologinCTA: false,
  hideGetStartedCTA: false,
  hideGoBackCTA: false,
  isPiiAuthGate: false,
};

LoginSlidingPanels.Heading = Heading;
LoginSlidingPanels.SlidingPanel = SlidingPanel;
LoginSlidingPanels.SignUpFooter = SignUpFooter;

export default LoginSlidingPanels;

import React from 'react';

import styled from 'styled-components';

const Svg = styled.svg`
  color: ${({ theme }) => theme.colors.iconColor};
`;

const EmailIcon = () => {
  return (
    <Svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="currentColor"
          d="M11.05 0H1.95C1.43283 0 0.936838 0.205446 0.571142 0.571142C0.205446 0.936838 0 1.43283 0 1.95V8.45C0 8.96717 0.205446 9.46316 0.571142 9.82886C0.936838 10.1946 1.43283 10.4 1.95 10.4H11.05C11.5672 10.4 12.0632 10.1946 12.4289 9.82886C12.7946 9.46316 13 8.96717 13 8.45V1.95C13 1.43283 12.7946 0.936838 12.4289 0.571142C12.0632 0.205446 11.5672 0 11.05 0ZM10.6145 1.3L6.5 4.3875L2.3855 1.3H10.6145ZM11.05 9.1H1.95C1.77761 9.1 1.61228 9.03152 1.49038 8.90962C1.36848 8.78772 1.3 8.62239 1.3 8.45V2.1125L6.11 5.72C6.22251 5.80438 6.35936 5.85 6.5 5.85C6.64064 5.85 6.77749 5.80438 6.89 5.72L11.7 2.1125V8.45C11.7 8.62239 11.6315 8.78772 11.5096 8.90962C11.3877 9.03152 11.2224 9.1 11.05 9.1Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="13" height="10.4" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default EmailIcon;

import React from 'react';

import PropTypes from 'prop-types';

export default function ChatBubbleIcon({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" {...rest}>
      <path
        d="M4 2.47803C3.46957 2.47803 2.96086 2.68874 2.58579 3.06381C2.21071 3.43889 2 3.94759 2 4.47803V20.478C2 21.0085 2.21071 21.5172 2.58579 21.8922C2.96086 22.2673 3.46957 22.478 4 22.478H23.172C24.2328 22.4783 25.25 22.8998 26 23.65L30 27.65V4.47803C30 3.94759 29.7893 3.43889 29.4142 3.06381C29.0391 2.68874 28.5304 2.47803 28 2.47803H4ZM28 0.478027C29.0609 0.478027 30.0783 0.899455 30.8284 1.6496C31.5786 2.39975 32 3.41716 32 4.47803V30.064C32 30.2619 31.9412 30.4553 31.8312 30.6198C31.7212 30.7842 31.5649 30.9124 31.382 30.988C31.1991 31.0636 30.998 31.0832 30.8039 31.0444C30.6099 31.0056 30.4317 30.9101 30.292 30.77L24.586 25.064C24.211 24.6889 23.7024 24.4781 23.172 24.478H4C2.93913 24.478 1.92172 24.0566 1.17157 23.3065C0.421427 22.5563 0 21.5389 0 20.478V4.47803C0 3.41716 0.421427 2.39975 1.17157 1.6496C1.92172 0.899455 2.93913 0.478027 4 0.478027L28 0.478027Z"
        fill={color}
      />
    </svg>
  );
}

ChatBubbleIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChatBubbleIcon from '~/icons/components/ChatBubbleIcon';
import EmailIcon from '~/icons/components/EmailIcon';
import { CaretRight } from '~/icons/fabletics';
import { useAccountActions } from '~/techstyle-shared/react-accounts';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

const AccountVerificationLoginOr = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 12px 0 5px;
  display: block;
`;

const VerificationLoginSection = styled.div`
  border: 1px solid
    ${({ theme }) => theme.colors.pinVerificationLoginSectionBorder};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  margin: 6px 0;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.pinVerificationLoginSectionHoverBg};
  }
`;

const StyledCaretRight = styled(CaretRight)`
  margin-left: 10px;
`;

const AccountVerificationLoginSelectText = styled(AccountVerificationLoginOr)`
  padding: 12px 0 24px;
`;

const AccountVerificationLoginMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const VerificationMethodIconContainer = styled.div`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.pinVerificationMethodIcon};
  margin-right: 12px;
`;

const VerificationMethodTextContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  justify-content: left;
  text-align: left;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const HiddenPiiText = styled.div`
  font-weight: 700;
`;

const PinVerificationMethodsContainer = styled.div`
  max-width: 400px;
`;

const PinVerificationMethods = ({
  email,
  onVerificationMethodSelect,
  showVerifyIdentityTitle = true,
}) => {
  const actions = useAccountActions();
  const [maskedContactInfo, setMaskedContactInfo] = useState({
    email: email ? `${email.slice(0, 3)}***@***` : null,
    phone: null,
  });

  const [loginMethods, setLoginMethods] = useState({
    sms: false,
    email: false,
    confirm: '',
  });

  const showAdditionalLoginMethods = loginMethods?.email || loginMethods?.sms;

  useEffect(() => {
    async function fetchLoginMethods() {
      try {
        const response = await actions.loginMethodsFromEmail(email);
        const loginMethods = {
          sms: response?.payload?.sms,
          email: response?.payload?.email,
          confirm: response?.payload?.confirm,
        };
        setLoginMethods(loginMethods);
        setMaskedContactInfo({
          email: email ? `${email.slice(0, 3)}***@***` : null,
          phone: loginMethods?.confirm
            ? `XXX-XXX-${loginMethods?.confirm}`
            : null,
        });
      } catch (error) {
        // Do nothing.
      }
    }

    fetchLoginMethods();
  }, [actions, email, setLoginMethods, setMaskedContactInfo]);

  if (!email || !showAdditionalLoginMethods) {
    return null;
  }

  return (
    <PinVerificationMethodsContainer>
      {showVerifyIdentityTitle && (
        <AccountVerificationLoginSelectText>
          <FormattedMessage
            id="site_login.identity_verification_subtext"
            defaultMessage="Choose to verify your identity using an email code, SMS code, or your password."
          />
        </AccountVerificationLoginSelectText>
      )}
      <AccountVerificationLoginMethodsContainer>
        {maskedContactInfo.email && (
          <VerificationLoginSection
            onClick={() => onVerificationMethodSelect('email')}
          >
            <VerificationMethodIconContainer>
              <EmailIcon />
            </VerificationMethodIconContainer>
            <VerificationMethodTextContainer>
              <FormattedMessage
                id="site_login.sign_in_link"
                defaultMessage="Get a sign in link or code to "
              />
              <HiddenPiiText>{maskedContactInfo.email}</HiddenPiiText>
            </VerificationMethodTextContainer>
            <IconContainer>
              <StyledCaretRight strokeWidth="5" />
            </IconContainer>
          </VerificationLoginSection>
        )}

        {maskedContactInfo.phone && (
          <VerificationLoginSection
            onClick={() => onVerificationMethodSelect('sms')}
          >
            <VerificationMethodIconContainer>
              <ChatBubbleIcon />
            </VerificationMethodIconContainer>
            <VerificationMethodTextContainer>
              <FormattedMessage
                id="site_login.text_code_to_phone_number"
                defaultMessage="Text code to number ending in "
              />
              <HiddenPiiText>{maskedContactInfo.phone}</HiddenPiiText>
            </VerificationMethodTextContainer>
            <IconContainer>
              <StyledCaretRight strokeWidth="5" />
            </IconContainer>
          </VerificationLoginSection>
        )}
        <AccountVerificationLoginOr>
          <FormattedMessage id="site_login.sign_in_or" defaultMessage="or" />
        </AccountVerificationLoginOr>
      </AccountVerificationLoginMethodsContainer>
    </PinVerificationMethodsContainer>
  );
};

PinVerificationMethods.propTypes = {
  email: PropTypes.string,
  onVerificationMethodSelect: PropTypes.func.isRequired,
  showVerifyIdentityTitle: PropTypes.bool,
};

export default PinVerificationMethods;

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '~/techstyle-shared/react-intl';

export const AUTOLOGIN_FAILED = '2';

export const AuthenticationErrorTypes = {
  BLOCKED_LOGIN: 'BLOCKED_LOGIN',
  CANCELLED_USER: 'CANCELLED_USER',
  LOGIN_FAILED: 'LOGIN_FAILED',
  SERVER_ERROR: 'SERVER_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  INVALID_RECAPTCHA: 'INVALID_RECAPTCHA',
  RECAPTCHA_REQUIRED: 'RECAPTCHA_REQUIRED',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  PASSWORD_MIN_CHAR: 'PASSWORD_MIN_CHAR',
  AUTOLOGIN_FAILED: 'AUTOLOGIN_FAILED',
  INVALID_EMAIL: 'INVALID_EMAIL',
};

const ErrorMessageWrapper = styled.div.attrs({
  'data-autotag': 'login_page_error_msg',
})`
  ${({ theme }) => theme.loginForm.errorMessage};
  padding-top: 10px;
`;

export function LoginErrorMessage({ error }) {
  switch (error) {
    case AuthenticationErrorTypes.BLOCKED_LOGIN:
      return (
        <ErrorMessageWrapper>
          <FormattedMessage
            id="site_login.blocked_login_error"
            defaultMessage="Sorry, your login attempt has been blocked due to suspicious activity. Please try an alternate login method, or contact support."
          />
        </ErrorMessageWrapper>
      );
    case AuthenticationErrorTypes.AUTOLOGIN_FAILED:
      return (
        <ErrorMessageWrapper>
          <FormattedMessage
            id="site_login.autologin_failed"
            defaultMessage="There was an issue with your login. Please try again."
          />
        </ErrorMessageWrapper>
      );
    case AuthenticationErrorTypes.LOGIN_FAILED:
      return (
        <ErrorMessageWrapper>
          <FormattedMessage
            id="site_login.error_address_or_password"
            defaultMessage="The email address or password you entered doesn't match our records."
          />
        </ErrorMessageWrapper>
      );
    case AuthenticationErrorTypes.EMAIL_REQUIRED:
      return (
        <ErrorMessageWrapper>
          <FormattedMessage
            id="site_login.error_email_required"
            defaultMessage="Email is required"
          />
        </ErrorMessageWrapper>
      );
    case AuthenticationErrorTypes.INVALID_EMAIL:
      return (
        <ErrorMessageWrapper>
          <FormattedMessage
            id="site_login.error_invalid_email"
            defaultMessage="Please enter a valid email address."
          />
        </ErrorMessageWrapper>
      );
    case AuthenticationErrorTypes.SERVER_ERROR:
    case AuthenticationErrorTypes.UNKNOWN_ERROR:
      return (
        <ErrorMessageWrapper>
          <FormattedMessage
            id="app_global.404_error_message"
            defaultMessage="Something went wrong. We are working to fix it ASAP. Please try again!"
          />
        </ErrorMessageWrapper>
      );
    default:
      return null;
  }
}

LoginErrorMessage.propTypes = {
  error: PropTypes.string,
};

export function getAuthenticationErrorType({ errorCode, statusCode }) {
  if ([41000, 42000].includes(errorCode) || [403, 429].includes(statusCode)) {
    return AuthenticationErrorTypes.BLOCKED_LOGIN;
  }
  if (errorCode === 40001) {
    return AuthenticationErrorTypes.CANCELLED_USER;
  }
  if (errorCode === 40003) {
    return AuthenticationErrorTypes.RECAPTCHA_REQUIRED;
  }
  if (errorCode === 40004) {
    return AuthenticationErrorTypes.INVALID_RECAPTCHA;
  }
  if (statusCode >= 400 && statusCode < 500) {
    return AuthenticationErrorTypes.LOGIN_FAILED;
  }
  if (statusCode >= 500) {
    return AuthenticationErrorTypes.SERVER_ERROR;
  }
  return AuthenticationErrorTypes.UNKNOWN_ERROR;
}

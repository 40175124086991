import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  AuthenticationErrorTypes,
  LoginErrorMessage,
} from '~/login/utils/LoginErrorMessages';
import InputFields from '~/shared/components/InputFields';
import { Button, TextField, mobile } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

const actionButtonMobileStyles = css`
  height: 52px;
  width: 100%;
`;

const LoginButton = styled(Button).attrs({ variant: 'primary' })`
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;

  ${mobile`
    ${actionButtonMobileStyles}
    margin-bottom: 12px;
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PublicMessage = styled.div`
  font-size: 13px;
  margin-bottom: 24px;
  margin-top: ${({ theme }) => theme.sizes.md}px;
  text-align: center;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 14px;
  left: 12px;
  ${mobile`
    top: 34px;
  `}
`;

const UsernameInputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
`;

function UsernameInputField({
  onSubmitEmail,
  collectedEmailRef,
  alError = null,
}) {
  const [error, setError] = useState(alError);
  const [userName, setUserName] = useState(collectedEmailRef?.current || '');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleFormSubmit = useCallback(() => {
    if (userName === '') {
      setError(AuthenticationErrorTypes.EMAIL_REQUIRED);
      return;
    }

    if (!emailRegex.test(userName)) {
      setError(AuthenticationErrorTypes.INVALID_EMAIL);
      return;
    }

    // Store the email value in collectedEmailRef.current
    if (collectedEmailRef) {
      collectedEmailRef.current = userName;
    }
    onSubmitEmail();
  }, [onSubmitEmail, collectedEmailRef, userName, emailRegex]);

  return (
    <UsernameInputFieldContainer>
      {error && <LoginErrorMessage error={error} />}
      <InputFields>
        <TextField
          name="email"
          variant="standard"
          type="email"
          onChange={(e) => setUserName(e.target.value)}
          value={userName}
          required
          label={
            <FormattedMessage
              id="global_cta.email"
              defaultMessage="Email Address *"
            />
          }
          data-autotag="login_page_email"
          error={error === AuthenticationErrorTypes.LOGIN_FAILED}
        />
      </InputFields>

      <PublicMessage>
        <FormattedMessage
          defaultMessage="Don't forget to go incognito or log out if you are on a public computer!"
          id="site_login.public_warning"
        />
      </PublicMessage>

      <Actions>
        <LoginButton
          type="submit"
          onClick={handleFormSubmit}
          data-autotag="username_input_field_login_btn"
        >
          <FormattedMessage id="site_login.Sign_In" defaultMessage="Sign In" />
        </LoginButton>
      </Actions>
    </UsernameInputFieldContainer>
  );
}

UsernameInputField.propTypes = {
  alError: PropTypes.string,
  collectedEmailRef: PropTypes.object,
  onSubmitEmail: PropTypes.func,
};

UsernameInputField.BackButtonContainer = BackButtonContainer;

UsernameInputField.defaultProps = {};

export default UsernameInputField;

const EXCLUDED_PATHS = ['/products/', '/outlet-splash'];

const getAutoLoginByEmailRedirect = ({
  email,
  baseUrl = window.location,
  pathPrefix = '',
}) => {
  const windowHash = window?.location?.hash;
  let passThruDestination = '';

  // This is to support legacy passthru parameters
  // ex: https://www.fabletics.com/login#passthru=friends.main%26usa%3D0%26frommobileapp%3Dfalse%26enable%5Frma%3D0%26aus%3D0
  if (
    windowHash?.length &&
    windowHash.includes('#passthru=') &&
    windowHash.includes('.main')
  ) {
    passThruDestination = windowHash.split('#passthru=')[1]?.split('.')[0];
  }

  const url = new URL(baseUrl);

  const homepageRedirectException = EXCLUDED_PATHS.some((path) =>
    url.pathname.includes(path)
  );

  if (!homepageRedirectException) {
    url.pathname = '';
  }

  if (passThruDestination && passThruDestination !== '') {
    url.searchParams.append('destination', passThruDestination);
  }

  url.pathname = `${pathPrefix}${url.pathname}`;
  url.searchParams.append('alemail', email);

  return url.toString();
};

export default getAutoLoginByEmailRedirect;

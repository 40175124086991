import React, { useState } from 'react';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InputFields from '~/shared/components/InputFields';
import { useAccountActions } from '~/techstyle-shared/react-accounts';
import { Button, TextField } from '~/techstyle-shared/react-components';
import { FormattedMessage, useIntl } from '~/techstyle-shared/react-intl';

const ValidationMessages = {
  PASSWORD_SENT: 'PASSWORD_SENT',
};

const Form = styled.form``;
const FormWrapper = styled.div`
  width: 100%;
`;

const ResetButton = styled(Button)`
  width: 100%;
  margin-top: 1.125em;
`;

const ErrorMessageWrapper = styled.div.attrs({
  'data-autotag': 'login_page_error_msg',
})`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.sizes.md}px;
  text-align: center;
`;

export default function ForgotPassword({
  onEmailSent,
  className,
  email,
  ...props
}) {
  const { formatMessage } = useIntl();
  const [reCaptchaResponse] = useState(null);
  const actions = useAccountActions();

  const handleError = async (err) => {
    let errorCode;
    if (err.originalError && err.originalError.response) {
      const responseBody = await err.originalError.response.json();
      if (responseBody && responseBody.errorCode) {
        errorCode = responseBody.errorCode;
      }
    }
    if (errorCode === 30002) {
      return {
        error: formatMessage({
          id: 'site_login.invalid_email_yitty',
          defaultMessage: 'Email Address Not Found',
        }),
      };
    } else {
      return {
        error: formatMessage({
          id: 'app_global.404_error_message_yitty',
          defaultMessage:
            'Something went wrong. We are working to fix it ASAP. Please try again!',
        }),
      };
    }
  };

  const handleOnSubmit = async (values, { setStatus, setSubmitting }) => {
    let payload;
    let error;

    try {
      payload = await actions.forgotPassword({
        email: values.email,
        reCaptchaResponse,
      });
    } catch (err) {
      error = await handleError(err);
    }

    setSubmitting(false);
    setStatus({
      success: ValidationMessages.PASSWORD_SENT,
      ...payload,
      ...error,
    });

    if (onEmailSent && !error) {
      onEmailSent();
    }
  };

  return (
    <FormWrapper className={className} id="forgot" {...props}>
      <Formik
        initialValues={{ email: email ?? '' }}
        initialStatus={{}}
        validateOnChange={false}
        onSubmit={handleOnSubmit}
        render={({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
          isSubmitting,
          errors,
          status,
          dirty,
        }) => {
          const disabled = !values.email;
          return (
            <Form name="forgot-password" onSubmit={handleSubmit}>
              <>
                {status?.error && (
                  <ErrorMessageWrapper>{status.error}</ErrorMessageWrapper>
                )}
                <InputFields>
                  <TextField
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={!!email}
                    label={
                      <FormattedMessage
                        id="global_cta.email"
                        defaultMessage="Email Address *"
                      />
                    }
                    data-autotag="forgot_pw_email_field"
                  />
                </InputFields>
              </>

              <ResetButton
                variant="primary"
                type="submit"
                disabled={disabled}
                isLoading={isSubmitting}
                data-autotag="forgot_pw_submit_cta"
              >
                <FormattedMessage
                  id="site_login.reset_your_password"
                  defaultMessage="Reset Password"
                />
              </ResetButton>
            </Form>
          );
        }}
      />
    </FormWrapper>
  );
}

ForgotPassword.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  onEmailSent: PropTypes.func,
};
